.subscription-heading {
  text-align: center;
  margin-bottom: 50px;
  font-weight: 900;
}

.spinner {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.subscription-card {
  margin: 10px 0 10px 0;
  width: 100%;
  background: white;
  border-radius: 10px;
  padding: 50px 40px;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
}

@media (max-width: 768px) {
  .subscription-card {
    padding: 20px 20px;
  }
  .subscription-card-heading {
    font-size: 30px !important;
  }
  .subscription-card-btn {
    width: 100% !important;
  }
  .subscription-card-pricing-h3 {
    text-align: center;
  }
  .subscription-card-pricing-h3-two {
    text-align: center;
  }
  .subscription-card-pricing-h1 {
    font-size: 30px !important;
  }
}

.subscription-card-inner {
  width: 100%;
  text-align: center;
}
.subscription-card-heading {
  font-weight: 900;
  color: black;
  font-size: 36px;
  text-transform: uppercase;
}
.subscription-card-desc {
  margin: 20px 0;
  color: black;
}

.subscription-card-span {
  font-weight: 900;
}

.subscription-card-pricing {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
.subscription-card-pricing-h1 {
  font-weight: 900;
  color: black;
  font-size: 44px;
}

.subscription-card-pricing-div {
  text-align: left;
}

.subscription-card-pricing-h3 {
  color: black;
  font-weight: 900;
  text-transform: capitalize;
}

.subscription-card-pricing-h3-two {
  color: black;
}

.subscription-card-btn {
  cursor: pointer;
  width: 200px;
  height: 50px;
  margin-top: 20px;
}

.subscription-change-btn {
  cursor: pointer;
  width: 120px;
  height: 50px;
  margin-top: 20px;
}

@media (max-width: 1560px) {
  .subscription-card-pricing {
    flex-direction: column;
    align-items: center;
  }
}

/* SubscriptionPagePopUp */

.subscription-h1 {
  margin: 20px 0 !important;
  text-align: center !important;
}

.table-total-amount-div {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
