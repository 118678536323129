:root {
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-460bc02: #de2929;
  --e-global-color-fe6c737: #2a2a2a;
  --e-global-color-76dacb9: #373737;
  --e-global-color-cc9ba2f: #ffffff;
  --e-global-color-0e5597d: #edf0f9;
  --e-global-color-7cfe9b8: #e74d4d;
  --e-global-typography-primary-font-family: "Poppins";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Poppins";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Poppins";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Poppins";
  --e-global-typography-accent-font-weight: 500;
  font-family: "Poppins", Sans-serif;
}

.elementor-kit-11 a {
  font-family: "Poppins", Sans-serif;
}
.elementor-kit-11 h1 {
  font-family: "Poppins", Sans-serif;
}
.elementor-kit-11 h2 {
  font-family: "Poppins", Sans-serif;
}
.elementor-kit-11 h3 {
  font-family: "Poppins", Sans-serif;
}
.elementor-kit-11 h4 {
  font-family: "Poppins", Sans-serif;
}
.elementor-kit-11 h5 {
  font-family: "Poppins", Sans-serif;
}
.elementor-kit-11 h6 {
  font-family: "Poppins", Sans-serif;
}
.elementor-kit-11 button,
.elementor-kit-11 input[type="button"],
.elementor-kit-11 input[type="submit"],
.elementor-kit-11 .elementor-button {
  font-family: "Poppins", Sans-serif;
}
.elementor-section.elementor-section-boxed > .elementor-container {
  max-width: 1140px;
}
.e-con {
  --container-max-width: 1140px;
}
.elementor-widget:not(:last-child) {
  margin-bottom: 20px;
}
.elementor-element {
  --widgets-spacing: 20px;
}

h1.entry-title {
  display: var(--page-title-display);
}
.elementor-kit-11 e-page-transition {
  background-color: #ffbc7d;
}
@media (max-width: 1024px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 1024px;
  }
  .e-con {
    --container-max-width: 1024px;
  }
}
@media (max-width: 767px) {
  .elementor-section.elementor-section-boxed > .elementor-container {
    max-width: 767px;
  }
  .e-con {
    --container-max-width: 767px;
  }
}
.elementor-1632
  .elementor-element.elementor-element-57675cd:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-57675cd
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-0e5597d);
}
.elementor-1632 .elementor-element.elementor-element-57675cd {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-57675cd
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-cbb7b9e:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-cbb7b9e
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-cc9ba2f);
}
.elementor-1632
  .elementor-element.elementor-element-cbb7b9e
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-cbb7b9e
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-cbb7b9e
  > .elementor-background-slideshow {
  border-radius: 10px 10px 10px 10px;
}
.elementor-1632
  .elementor-element.elementor-element-cbb7b9e
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-cbb7b9e
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .elementor-1632
  .elementor-element.elementor-element-d6ceb4c.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-d6ceb4c.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-d6ceb4c
  > .elementor-element-populated {
  padding: 10px 100px 10px 50px;
}
.elementor-1632
  .elementor-element.elementor-element-d87f4ae
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.3em;
}
.elementor-1632 .elementor-element.elementor-element-e2a2ffd {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.elementor-1632 .elementor-element.elementor-element-872a977 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  background-color: var(--e-global-color-460bc02);
  border-radius: 5px 5px 5px 5px;
  padding: 11px 30px 11px 30px;
}
.elementor-1632
  .elementor-element.elementor-element-872a977
  .elementor-button:hover,
.elementor-1632
  .elementor-element.elementor-element-872a977
  .elementor-button:focus {
  background-color: var(--e-global-color-fe6c737);
}
.elementor-1632
  .elementor-element.elementor-element-872a977
  > .elementor-widget-container {
  padding: 0px 30px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-872a977 {
  width: auto;
  max-width: auto;
}
.elementor-1632 .elementor-element.elementor-element-340020a {
  text-align: left;
  width: auto;
  max-width: auto;
}
.elementor-1632 .elementor-element.elementor-element-340020a img {
  width: 120px;
}
.elementor-1632
  .elementor-element.elementor-element-98dee7b
  > .elementor-widget-container {
  margin: -70px -50px -70px 50px;
}
.elementor-1632 .elementor-element.elementor-element-ce3a764 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  background-color: var(--e-global-color-460bc02);
  border-radius: 5px 5px 5px 5px;
  padding: 11px 30px 11px 30px;
}
.elementor-1632
  .elementor-element.elementor-element-ce3a764
  .elementor-button:hover,
.elementor-1632
  .elementor-element.elementor-element-ce3a764
  .elementor-button:focus {
  background-color: var(--e-global-color-fe6c737);
}
.elementor-1632
  .elementor-element.elementor-element-ce3a764
  > .elementor-widget-container {
  padding: 0px 30px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-ce3a764 {
  width: auto;
  max-width: auto;
}
.elementor-1632 .elementor-element.elementor-element-834159a {
  text-align: left;
  width: auto;
  max-width: auto;
}
.elementor-1632 .elementor-element.elementor-element-834159a img {
  width: 120px;
}
.elementor-1632
  .elementor-element.elementor-element-68d915d:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-68d915d
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-460bc02);
}

.elementor-1632 .elementor-element.elementor-element-68d915d,
.elementor-1632
  .elementor-element.elementor-element-68d915d
  > .elementor-background-overlay {
  border-radius: 20px 20px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-68d915d {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 50px;
  margin-bottom: 0px;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-68d915d
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-efad16d.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}
.elementor-1632 .elementor-element.elementor-element-dc5f7ab {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-dc5f7ab
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 25px;
  font-weight: 600;
}
.elementor-1632 .elementor-element.elementor-element-0381c66 {
  text-align: center;
  width: var(--container-widget-width, 60%);
  max-width: 60%;
  --container-widget-width: 60%;
  --container-widget-flex-grow: 0;
}
.elementor-1632
  .elementor-element.elementor-element-0381c66
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-0381c66
  > .elementor-widget-container {
  padding: 0px 0px 20px 0px;
}
.elementor-1632 .elementor-element.elementor-element-edddbdd {
  text-align: center;
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.elementor-1632 .elementor-element.elementor-element-3a692c9 {
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-4d3d910.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}
.elementor-1632 .elementor-element.elementor-element-92daedc {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-92daedc
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 700;
}
.elementor-1632 .elementor-element.elementor-element-6701417 {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: var(--container-widget-width, 56%);
  max-width: 56%;
  --container-widget-width: 56%;
  --container-widget-flex-grow: 0;
}
.elementor-1632
  .elementor-element.elementor-element-6701417
  > .elementor-widget-container {
  padding: 0px 0px 30px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-1004a15
  > .elementor-container {
  max-width: 1000px;
}
.elementor-1632
  .elementor-element.elementor-element-d566823
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-d566823
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-d566823
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-d566823
  > .elementor-element-populated {
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-2974770
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-2974770
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-2974770
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-2974770
  > .elementor-element-populated {
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-788010a
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-788010a
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-788010a
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-788010a
  > .elementor-element-populated {
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-526fb6d
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-526fb6d
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-526fb6d
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-526fb6d
  > .elementor-element-populated {
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-fee0952
  > .elementor-container {
  max-width: 1000px;
}
.elementor-1632
  .elementor-element.elementor-element-e778884:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-e778884
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-1632
  .elementor-element.elementor-element-e778884
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-e778884
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-e778884
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-e778884
  > .elementor-element-populated {
  box-shadow: 0px 0px 10px 0px
    rgba(99.00000000000001, 99.00000000000001, 99.00000000000001, 0.39);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-e778884
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-3d54b20 {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-3d54b20
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-3d54b20
  > .elementor-widget-container {
  padding: 0px 10px 0px 10px;
}
.elementor-1632 .elementor-element.elementor-element-95267ce {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-95267ce
  > .elementor-widget-container {
  padding: 0px 10px 0px 10px;
}
.elementor-1632
  .elementor-element.elementor-element-c8ad72c:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-c8ad72c
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-1632
  .elementor-element.elementor-element-c8ad72c
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-c8ad72c
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-c8ad72c
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-c8ad72c
  > .elementor-element-populated {
  box-shadow: 0px 0px 10px 0px
    rgba(99.00000000000001, 99.00000000000001, 99.00000000000001, 0.39);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-c8ad72c
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-575c6b4 {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-575c6b4
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-575c6b4
  > .elementor-widget-container {
  padding: 0px 10px 0px 10px;
}
.elementor-1632 .elementor-element.elementor-element-6b5a9e0 {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-6b5a9e0
  > .elementor-widget-container {
  padding: 0px 10px 0px 10px;
}
.elementor-1632
  .elementor-element.elementor-element-2c420c2:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-2c420c2
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-1632
  .elementor-element.elementor-element-2c420c2
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-2c420c2
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-2c420c2
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-2c420c2
  > .elementor-element-populated {
  box-shadow: 0px 0px 10px 0px
    rgba(99.00000000000001, 99.00000000000001, 99.00000000000001, 0.39);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-2c420c2
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-5fe736f {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-5fe736f
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-5fe736f
  > .elementor-widget-container {
  padding: 0px 15px 0px 15px;
}
.elementor-1632 .elementor-element.elementor-element-84c26da {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-84c26da
  > .elementor-widget-container {
  padding: 0px 10px 0px 10px;
}
.elementor-bc-flex-widget
  .elementor-1632
  .elementor-element.elementor-element-02359bb.elementor-column
  .elementor-widget-wrap {
  align-items: space-between;
}
.elementor-1632
  .elementor-element.elementor-element-02359bb.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: space-between;
  align-items: space-between;
}
.elementor-1632
  .elementor-element.elementor-element-02359bb:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-02359bb
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-1632
  .elementor-element.elementor-element-02359bb
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-02359bb
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-02359bb
  > .elementor-background-slideshow {
  border-radius: 20px 20px 20px 20px;
}
.elementor-1632
  .elementor-element.elementor-element-02359bb
  > .elementor-element-populated {
  box-shadow: 0px 0px 10px 0px
    rgba(99.00000000000001, 99.00000000000001, 99.00000000000001, 0.39);
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 15px 0px 15px;
  --e-column-margin-right: 15px;
  --e-column-margin-left: 15px;
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-02359bb
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-4cede93 {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-4cede93
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-4cede93
  > .elementor-widget-container {
  padding: 0px 10px 0px 10px;
}
.elementor-1632 .elementor-element.elementor-element-264a937 {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-264a937
  > .elementor-widget-container {
  padding: 0px 10px 0px 10px;
}
.elementor-1632
  .elementor-element.elementor-element-6933f8c:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-6933f8c
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("https://wordpress-340463-3098573.cloudwaysapps.com/wp-content/uploads/2023/01/5.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1632 .elementor-element.elementor-element-6933f8c {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-6933f8c
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-984572b {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-984572b
  > .elementor-widget-container {
  padding: 0px 0px 0px 70px;
}
.elementor-bc-flex-widget
  .elementor-1632
  .elementor-element.elementor-element-0117706.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-0117706.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-0117706
  > .elementor-element-populated {
  padding: 0px 10px 0px 80px;
}
.elementor-1632 .elementor-element.elementor-element-8b4f0e2 {
  text-align: right;
}
.elementor-1632
  .elementor-element.elementor-element-8b4f0e2
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 45px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-8b4f0e2
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-ed18f0c {
  text-align: right;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-ed18f0c
  > .elementor-widget-container {
  margin: 0px 0px 0px 100px;
}
.elementor-1632
  .elementor-element.elementor-element-24317ce:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-24317ce
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("https://wordpress-340463-3098573.cloudwaysapps.com/wp-content/uploads/2023/01/01-HORIZ-WEBSITE-2023.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1632 .elementor-element.elementor-element-24317ce {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-24317ce
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .elementor-1632
  .elementor-element.elementor-element-ab522f2.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-ab522f2.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-ab522f2
  > .elementor-element-populated {
  padding: 0px 10px 0px 100px;
}
.elementor-1632 .elementor-element.elementor-element-5bf51cf {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-5bf51cf
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 45px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-5bf51cf
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-4531256 {
  text-align: left;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-4531256
  > .elementor-widget-container {
  margin: 0px 50px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-8af8da3 {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-8af8da3
  > .elementor-widget-container {
  padding: 0px 0px 0px 70px;
}
.elementor-1632
  .elementor-element.elementor-element-1008116:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-1008116
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("https://wordpress-340463-3098573.cloudwaysapps.com/wp-content/uploads/2023/01/01-HORIZ-WEBSITE-2023.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1632 .elementor-element.elementor-element-1008116 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-1008116
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-bc-flex-widget
  .elementor-1632
  .elementor-element.elementor-element-d87bf65.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-d87bf65.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-d87bf65
  > .elementor-element-populated {
  padding: 0px 0px 0px 100px;
}
.elementor-1632 .elementor-element.elementor-element-2239ddd {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-2239ddd
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 45px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-2239ddd
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-1f73d9c {
  text-align: left;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-1f73d9c
  > .elementor-widget-container {
  margin: 0px 50px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-16d5c16 {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-16d5c16
  > .elementor-widget-container {
  padding: 0px 0px 0px 70px;
}
.elementor-1632
  .elementor-element.elementor-element-fcde488:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-fcde488
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-image: url("https://wordpress-340463-3098573.cloudwaysapps.com/wp-content/uploads/2023/01/01-HORIZ-WEBSITE-2023.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.elementor-1632 .elementor-element.elementor-element-fcde488 {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-fcde488
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-6eb4421 {
  text-align: center;
}
.elementor-1632 .elementor-element.elementor-element-6eb4421 img {
  width: 350px;
}
.elementor-1632
  .elementor-element.elementor-element-6eb4421
  > .elementor-widget-container {
  padding: 0px 0px 0px 70px;
}
.elementor-bc-flex-widget
  .elementor-1632
  .elementor-element.elementor-element-6296a19.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-6296a19.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-6296a19
  > .elementor-element-populated {
  padding: 0px 80px 0px 10px;
}
.elementor-1632 .elementor-element.elementor-element-188d4f0 {
  text-align: right;
}
.elementor-1632
  .elementor-element.elementor-element-188d4f0
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 45px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.2em;
}
.elementor-1632
  .elementor-element.elementor-element-188d4f0
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-59be544 {
  text-align: right;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-59be544
  > .elementor-widget-container {
  margin: 0px 0px 0px 100px;
}
.elementor-1632
  .elementor-element.elementor-element-f6c402b
  > .elementor-container {
  max-width: 750px;
}
.elementor-1632
  .elementor-element.elementor-element-f6c402b:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-f6c402b
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-460bc02);
}
.elementor-1632 .elementor-element.elementor-element-f6c402b {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-f6c402b
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-10b740a.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}
.elementor-1632 .elementor-element.elementor-element-3b8451b {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-3b8451b
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 35px;
  font-weight: 600;
  line-height: 1.2em;
}
.elementor-1632 .elementor-element.elementor-element-61eafc9 {
  text-align: center;
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: var(--container-widget-width, 70%);
  max-width: 70%;
  --container-widget-width: 70%;
  --container-widget-flex-grow: 0;
}
.elementor-1632
  .elementor-element.elementor-element-61eafc9
  > .elementor-widget-container {
  padding: 0px 0px 20px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-2533881
  > .elementor-widget-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.29);
}
.elementor-1632
  .elementor-element.elementor-element-730a6be
  > .elementor-container {
  max-width: 1000px;
}
.elementor-1632 .elementor-element.elementor-element-730a6be {
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px 0px 100px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-960d8d7.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}
.elementor-1632
  .elementor-element.elementor-element-960d8d7:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-960d8d7
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-1632
  .elementor-element.elementor-element-960d8d7
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-960d8d7
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-960d8d7
  > .elementor-background-slideshow {
  border-radius: 0px 0px 25px 25px;
}
.elementor-1632
  .elementor-element.elementor-element-960d8d7
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 0px 0px 0px 0px;
  --e-column-margin-right: 0px;
  --e-column-margin-left: 0px;
  padding: 50px 0px 0px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-960d8d7
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-7e980f3 {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-7e980f3
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 45px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.3em;
}
.elementor-1632
  .elementor-element.elementor-element-76d00a1
  > .elementor-widget-container {
  margin: 0px 0px -100px 0px;
  padding: 0px 0px 0px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-0f069c5.elementor-column
  > .elementor-widget-wrap {
  justify-content: center;
}
.elementor-1632 .elementor-element.elementor-element-ff44f87 {
  text-align: center;
  width: var(--container-widget-width, 45%);
  max-width: 45%;
  --container-widget-width: 45%;
  --container-widget-flex-grow: 0;
}
.elementor-1632
  .elementor-element.elementor-element-ff44f87
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.3em;
}
.elementor-1632 .elementor-element.elementor-element-a706fab {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: var(--container-widget-width, 65%);
  max-width: 65%;
  --container-widget-width: 65%;
  --container-widget-flex-grow: 0;
}
.elementor-1632
  .elementor-element.elementor-element-a706fab
  > .elementor-widget-container {
  padding: 20px 0px 30px 0px;
}
.elementor-1632 .elementor-element.elementor-element-012cf07 {
  padding: 50px 0px 50px 0px;
}
.elementor-1632 .elementor-element.elementor-element-2c4ac61 {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-2c4ac61
  .elementor-heading-title {
  color: var(--e-global-color-460bc02);
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 1.3em;
}
.elementor-1632 .elementor-element.elementor-element-c76a18b {
  text-align: left;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: var(--container-widget-width, 47%);
  max-width: 47%;
  --container-widget-width: 47%;
  --container-widget-flex-grow: 0;
}
.elementor-1632
  .elementor-element.elementor-element-c76a18b
  > .elementor-widget-container {
  padding: 0px 0px 30px 0px;
}
.elementor-1632 .elementor-element.elementor-element-5cfc062 {
  text-align: left;
}
.elementor-bc-flex-widget
  .elementor-1632
  .elementor-element.elementor-element-c232fc2.elementor-column
  .elementor-widget-wrap {
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-c232fc2.elementor-column.elementor-element[data-element_type="column"]
  > .elementor-widget-wrap.elementor-element-populated {
  align-content: center;
  align-items: center;
}
.elementor-1632
  .elementor-element.elementor-element-c232fc2
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 5px;
}
.elementor-1632 .elementor-element.elementor-element-6511042 {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-6511042
  .elementor-heading-title {
  color: var(--e-global-color-460bc02);
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 1.3em;
}
.elementor-1632 .elementor-element.elementor-element-18a2e49 {
  text-align: left;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-18a2e49
  > .elementor-widget-container {
  padding: 10px 0px 30px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-bea7822
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-weight: 500;
}
.elementor-1632
  .elementor-element.elementor-element-8ef20f4
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-weight: 500;
}
.elementor-1632
  .elementor-element.elementor-element-2f55f68
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-weight: 500;
}
.elementor-1632
  .elementor-element.elementor-element-f809d03
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-weight: 500;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6.elementor-position-right
  .elementor-image-box-img {
  margin-left: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6.elementor-position-left
  .elementor-image-box-img {
  margin-right: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 5%;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6
  .elementor-image-box-wrapper {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: var(--e-global-color-fe6c737);
  font-size: 20px;
  font-weight: 400;
  line-height: 0.6em;
}
.elementor-1632
  .elementor-element.elementor-element-2ae40b6
  .elementor-image-box-description {
  color: #ffffff;
  font-size: 16px;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a.elementor-position-right
  .elementor-image-box-img {
  margin-left: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a.elementor-position-left
  .elementor-image-box-img {
  margin-right: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 5%;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a
  .elementor-image-box-wrapper {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: var(--e-global-color-fe6c737);
  font-size: 20px;
  font-weight: 400;
  line-height: 0.6em;
}
.elementor-1632
  .elementor-element.elementor-element-09b022a
  .elementor-image-box-description {
  color: #ffffff;
  font-size: 16px;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0.elementor-position-right
  .elementor-image-box-img {
  margin-left: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0.elementor-position-left
  .elementor-image-box-img {
  margin-right: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 5%;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0
  .elementor-image-box-wrapper {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: var(--e-global-color-fe6c737);
  font-size: 20px;
  font-weight: 400;
  line-height: 0.6em;
}
.elementor-1632
  .elementor-element.elementor-element-d04e7b0
  .elementor-image-box-description {
  color: #ffffff;
  font-size: 16px;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9.elementor-position-right
  .elementor-image-box-img {
  margin-left: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9.elementor-position-left
  .elementor-image-box-img {
  margin-right: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9.elementor-position-top
  .elementor-image-box-img {
  margin-bottom: 10px;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9
  .elementor-image-box-wrapper
  .elementor-image-box-img {
  width: 5%;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9
  .elementor-image-box-img
  img {
  transition-duration: 0.3s;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9
  .elementor-image-box-wrapper {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9
  .elementor-image-box-title {
  margin-bottom: 10px;
  color: var(--e-global-color-fe6c737);
  font-size: 20px;
  font-weight: 400;
  line-height: 0.6em;
}
.elementor-1632
  .elementor-element.elementor-element-2964df9
  .elementor-image-box-description {
  color: #ffffff;
  font-size: 16px;
}
.elementor-1632 .elementor-element.elementor-element-242ef00 {
  text-align: left;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-242ef00
  > .elementor-widget-container {
  padding: 30px 0px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-091ffd6 {
  padding: 50px 0px 50px 0px;
}
.elementor-1632 .elementor-element.elementor-element-2fc1424 {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-2fc1424
  .elementor-heading-title {
  color: #de2929;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 800;
  line-height: 1.3em;
}
.elementor-1632 .elementor-element.elementor-element-0322905 {
  text-align: left;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-0322905
  > .elementor-widget-container {
  padding: 0px 0px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-98cc316 {
  text-align: left;
}
.elementor-1632
  .elementor-element.elementor-element-8aec60e:not(
    .elementor-motion-effects-element-type-background
  ),
.elementor-1632
  .elementor-element.elementor-element-8aec60e
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: var(--e-global-color-460bc02);
}
.elementor-1632 .elementor-element.elementor-element-8aec60e {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 0px 50px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-8aec60e
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-0f6bebf {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-0f6bebf
  .elementor-heading-title {
  color: #ffffff;
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.3em;
}
.elementor-1632
  .elementor-element.elementor-element-4c8472b:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-4c8472b
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-1632
  .elementor-element.elementor-element-4c8472b
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-4c8472b
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-4c8472b
  > .elementor-background-slideshow {
  border-radius: 10px 10px 10px 10px;
}
.elementor-1632
  .elementor-element.elementor-element-4c8472b
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 20px 20px 20px 20px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 50px 50px 50px 50px;
}
.elementor-1632
  .elementor-element.elementor-element-4c8472b
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-372a150 {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-372a150
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 600;
}
.elementor-1632 .elementor-element.elementor-element-3eeaade {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-3eeaade
  > .elementor-widget-container {
  padding: 0px 50px 0px 50px;
}
.elementor-1632 .elementor-element.elementor-element-66c0b3b {
  text-align: right;
  width: var(--container-widget-width, 40%);
  max-width: 40%;
  --container-widget-width: 40%;
  --container-widget-flex-grow: 0;
  align-self: center;
}
.elementor-1632
  .elementor-element.elementor-element-66c0b3b
  .elementor-heading-title {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 600;
}
.elementor-1632
  .elementor-element.elementor-element-66c0b3b
  > .elementor-widget-container {
  padding: 0px 15px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-3aefd5a {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: var(--container-widget-width, 50%);
  max-width: 50%;
  --container-widget-width: 50%;
  --container-widget-flex-grow: 0;
}
.elementor-1632 .elementor-element.elementor-element-9600abd .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  background-color: var(--e-global-color-460bc02);
  border-radius: 5px 5px 5px 5px;
  padding: 11px 30px 11px 30px;
}
.elementor-1632
  .elementor-element.elementor-element-9600abd
  .elementor-button:hover,
.elementor-1632
  .elementor-element.elementor-element-9600abd
  .elementor-button:focus {
  background-color: var(--e-global-color-fe6c737);
}
.elementor-1632
  .elementor-element.elementor-element-9600abd
  > .elementor-widget-container {
  padding: 20px 0px 0px 0px;
}
.elementor-1632
  .elementor-element.elementor-element-cf320d6:not(
    .elementor-motion-effects-element-type-background
  )
  > .elementor-widget-wrap,
.elementor-1632
  .elementor-element.elementor-element-cf320d6
  > .elementor-widget-wrap
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #ffffff;
}
.elementor-1632
  .elementor-element.elementor-element-cf320d6
  > .elementor-element-populated,
.elementor-1632
  .elementor-element.elementor-element-cf320d6
  > .elementor-element-populated
  > .elementor-background-overlay,
.elementor-1632
  .elementor-element.elementor-element-cf320d6
  > .elementor-background-slideshow {
  border-radius: 10px 10px 10px 10px;
}
.elementor-1632
  .elementor-element.elementor-element-cf320d6
  > .elementor-element-populated {
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin: 20px 20px 20px 20px;
  --e-column-margin-right: 20px;
  --e-column-margin-left: 20px;
  padding: 50px 50px 50px 50px;
}
.elementor-1632
  .elementor-element.elementor-element-cf320d6
  > .elementor-element-populated
  > .elementor-background-overlay {
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}
.elementor-1632 .elementor-element.elementor-element-a5cd4d0 {
  text-align: center;
}
.elementor-1632
  .elementor-element.elementor-element-a5cd4d0
  .elementor-heading-title {
  color: var(--e-global-color-fe6c737);
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 600;
}
.elementor-1632 .elementor-element.elementor-element-2ebfda8 {
  text-align: center;
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
}
.elementor-1632
  .elementor-element.elementor-element-2ebfda8
  > .elementor-widget-container {
  padding: 0px 50px 0px 50px;
}
.elementor-1632 .elementor-element.elementor-element-a7f0a05 {
  text-align: right;
  width: var(--container-widget-width, 40%);
  max-width: 40%;
  --container-widget-width: 40%;
  --container-widget-flex-grow: 0;
  align-self: center;
}
.elementor-1632
  .elementor-element.elementor-element-a7f0a05
  .elementor-heading-title {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 40px;
  font-weight: 600;
}
.elementor-1632
  .elementor-element.elementor-element-a7f0a05
  > .elementor-widget-container {
  padding: 0px 15px 0px 0px;
}
.elementor-1632 .elementor-element.elementor-element-b516e07 {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 400;
  width: var(--container-widget-width, 50%);
  max-width: 50%;
  --container-widget-width: 50%;
  --container-widget-flex-grow: 0;
}
.elementor-1632 .elementor-element.elementor-element-75e3e6c .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  background-color: var(--e-global-color-460bc02);
  border-radius: 5px 5px 5px 5px;
  padding: 11px 30px 11px 30px;
}
.elementor-1632
  .elementor-element.elementor-element-75e3e6c
  .elementor-button:hover,
.elementor-1632
  .elementor-element.elementor-element-75e3e6c
  .elementor-button:focus {
  background-color: var(--e-global-color-fe6c737);
}
.elementor-1632
  .elementor-element.elementor-element-75e3e6c
  > .elementor-widget-container {
  padding: 20px 0px 0px 0px;
}
body.elementor-page-1632:not(.elementor-motion-effects-element-type-background),
body.elementor-page-1632
  > .elementor-motion-effects-container
  > .elementor-motion-effects-layer {
  background-color: #edf0f9;
}
@media (max-width: 1200px) {
  .elementor-1632
    .elementor-element.elementor-element-d6ceb4c
    > .elementor-element-populated {
    padding: 0px 50px 0px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-8b4f0e2
    .elementor-heading-title {
    font-size: 40px;
  }
  .elementor-1632
    .elementor-element.elementor-element-5bf51cf
    .elementor-heading-title {
    font-size: 40px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2239ddd
    .elementor-heading-title {
    font-size: 40px;
  }
  .elementor-1632
    .elementor-element.elementor-element-188d4f0
    .elementor-heading-title {
    font-size: 40px;
  }
}
@media (max-width: 1024px) {
  .elementor-1632 .elementor-element.elementor-element-57675cd {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-cbb7b9e
    > .elementor-element-populated {
    margin: 20px 20px 20px 20px;
    --e-column-margin-right: 20px;
    --e-column-margin-left: 20px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d6ceb4c
    > .elementor-element-populated {
    padding: 10px 10px 10px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d87f4ae
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-98dee7b img {
    width: 75%;
  }
  .elementor-1632
    .elementor-element.elementor-element-0381c66
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-0381c66 {
    --container-widget-width: 80%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 80%);
    max-width: 80%;
  }
  .elementor-1632
    .elementor-element.elementor-element-d566823
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2974770
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-788010a
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-526fb6d
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-e778884
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-3d54b20
    .elementor-heading-title {
    font-size: 15px;
  }
  .elementor-1632 .elementor-element.elementor-element-95267ce {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-c8ad72c
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-575c6b4
    .elementor-heading-title {
    font-size: 15px;
  }
  .elementor-1632 .elementor-element.elementor-element-6b5a9e0 {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2c420c2
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-5fe736f
    .elementor-heading-title {
    font-size: 15px;
  }
  .elementor-1632 .elementor-element.elementor-element-84c26da {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-02359bb
    > .elementor-element-populated {
    margin: 0px 5px 0px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
  }
  .elementor-1632
    .elementor-element.elementor-element-4cede93
    .elementor-heading-title {
    font-size: 15px;
  }
  .elementor-1632 .elementor-element.elementor-element-264a937 {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-8b4f0e2
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-ed18f0c {
    font-size: 17px;
  }
  .elementor-1632
    .elementor-element.elementor-element-5bf51cf
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-4531256 {
    font-size: 17px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2239ddd
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-1f73d9c {
    font-size: 17px;
  }
  .elementor-1632
    .elementor-element.elementor-element-188d4f0
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-59be544 {
    font-size: 17px;
  }
  .elementor-1632
    .elementor-element.elementor-element-10b740a
    > .elementor-element-populated {
    padding: 10px 100px 10px 100px;
  }
  .elementor-1632
    .elementor-element.elementor-element-3b8451b
    .elementor-heading-title {
    font-size: 25px;
  }
  .elementor-1632
    .elementor-element.elementor-element-7e980f3
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ae40b6.elementor-position-right
    .elementor-image-box-img {
    margin-left: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ae40b6.elementor-position-left
    .elementor-image-box-img {
    margin-right: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ae40b6.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ae40b6
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 8%;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ae40b6
    .elementor-image-box-title {
    font-size: 18px;
  }
  .elementor-1632
    .elementor-element.elementor-element-09b022a.elementor-position-right
    .elementor-image-box-img {
    margin-left: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-09b022a.elementor-position-left
    .elementor-image-box-img {
    margin-right: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-09b022a.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-09b022a
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 8%;
  }
  .elementor-1632
    .elementor-element.elementor-element-09b022a
    .elementor-image-box-title {
    font-size: 18px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d04e7b0.elementor-position-right
    .elementor-image-box-img {
    margin-left: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d04e7b0.elementor-position-left
    .elementor-image-box-img {
    margin-right: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d04e7b0.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d04e7b0
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 8%;
  }
  .elementor-1632
    .elementor-element.elementor-element-d04e7b0
    .elementor-image-box-title {
    font-size: 18px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2964df9.elementor-position-right
    .elementor-image-box-img {
    margin-left: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2964df9.elementor-position-left
    .elementor-image-box-img {
    margin-right: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2964df9.elementor-position-top
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2964df9
    .elementor-image-box-wrapper
    .elementor-image-box-img {
    width: 8%;
  }
  .elementor-1632
    .elementor-element.elementor-element-2964df9
    .elementor-image-box-title {
    font-size: 18px;
  }
  .elementor-1632
    .elementor-element.elementor-element-0f6bebf
    .elementor-heading-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .elementor-1632 .elementor-element.elementor-element-57675cd {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d87f4ae
    .elementor-heading-title {
    font-size: 25px;
  }
  .elementor-1632
    .elementor-element.elementor-element-72817f8.elementor-column
    > .elementor-widget-wrap {
    justify-content: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-72817f8
    > .elementor-element-populated {
    margin: 0px 0px 0px 0px;
    --e-column-margin-right: 0px;
    --e-column-margin-left: 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-98dee7b
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-ce3a764
    .elementor-button {
    font-size: 15px;
    padding: 11px 15px 11px 15px;
  }
  .elementor-1632
    .elementor-element.elementor-element-ce3a764
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-ce3a764 {
    width: var(--container-widget-width, 50%);
    max-width: 50%;
    --container-widget-width: 50%;
    --container-widget-flex-grow: 0;
  }
  .elementor-1632 .elementor-element.elementor-element-834159a img {
    width: 110px;
  }
  .elementor-1632
    .elementor-element.elementor-element-dc5f7ab
    .elementor-heading-title {
    font-size: 20px;
  }
  .elementor-1632
    .elementor-element.elementor-element-0381c66
    .elementor-heading-title {
    font-size: 17px;
  }
  .elementor-1632
    .elementor-element.elementor-element-0381c66
    > .elementor-widget-container {
    padding: 0px 0px 20px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-0381c66 {
    --container-widget-width: 100%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 100%);
    max-width: 100%;
  }
  .elementor-1632 .elementor-element.elementor-element-edddbdd {
    font-size: 13px;
  }
  .elementor-1632 .elementor-element.elementor-element-6701417 {
    font-size: 15px;
    --container-widget-width: 100%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 100%);
    max-width: 100%;
  }
  .elementor-1632 .elementor-element.elementor-element-1004a15 {
    padding: 10px 0px 10px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-d566823 {
    width: 25%;
  }
  .elementor-bc-flex-widget
    .elementor-1632
    .elementor-element.elementor-element-d566823.elementor-column
    .elementor-widget-wrap {
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-d566823.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-d566823
    > .elementor-element-populated {
    margin: 5px 5px 5px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-7ec2917
    > .elementor-widget-container {
    margin: 0px 0px -10px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-2974770 {
    width: 25%;
  }
  .elementor-1632
    .elementor-element.elementor-element-2974770
    > .elementor-element-populated {
    margin: 5px 5px 5px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 5px 5px 5px 5px;
  }
  .elementor-1632 .elementor-element.elementor-element-788010a {
    width: 25%;
  }
  .elementor-1632
    .elementor-element.elementor-element-788010a
    > .elementor-element-populated {
    margin: 5px 5px 5px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-1632 .elementor-element.elementor-element-526fb6d {
    width: 25%;
  }
  .elementor-1632
    .elementor-element.elementor-element-526fb6d
    > .elementor-element-populated {
    margin: 5px 5px 5px 5px;
    --e-column-margin-right: 5px;
    --e-column-margin-left: 5px;
    padding: 10px 10px 10px 10px;
  }
  .elementor-1632 .elementor-element.elementor-element-ab04a2d {
    width: 35%;
  }
  .elementor-1632
    .elementor-element.elementor-element-984572b
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-0117706 {
    width: 65%;
  }
  .elementor-1632
    .elementor-element.elementor-element-0117706
    > .elementor-element-populated {
    padding: 0px 10px 0px 20px;
  }
  .elementor-1632
    .elementor-element.elementor-element-8b4f0e2
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-1632 .elementor-element.elementor-element-ed18f0c {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-ed18f0c
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-ab522f2 {
    width: 60%;
  }
  .elementor-1632
    .elementor-element.elementor-element-ab522f2
    > .elementor-element-populated {
    padding: 0px 10px 0px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-5bf51cf
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-1632 .elementor-element.elementor-element-4531256 {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-4531256
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-b43cf89 {
    width: 35%;
  }
  .elementor-bc-flex-widget
    .elementor-1632
    .elementor-element.elementor-element-b43cf89.elementor-column
    .elementor-widget-wrap {
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-b43cf89.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-8af8da3
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-d87bf65 {
    width: 65%;
  }
  .elementor-1632
    .elementor-element.elementor-element-d87bf65
    > .elementor-element-populated {
    padding: 0px 10px 0px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2239ddd
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-1632 .elementor-element.elementor-element-1f73d9c {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-1f73d9c
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-bc045f0 {
    width: 35%;
  }
  .elementor-1632
    .elementor-element.elementor-element-16d5c16
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-3c706ef {
    width: 35%;
  }
  .elementor-1632
    .elementor-element.elementor-element-6eb4421
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-6296a19 {
    width: 65%;
  }
  .elementor-1632
    .elementor-element.elementor-element-6296a19
    > .elementor-element-populated {
    padding: 0px 10px 0px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-188d4f0
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-1632 .elementor-element.elementor-element-59be544 {
    font-size: 13px;
  }
  .elementor-1632
    .elementor-element.elementor-element-59be544
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-10b740a
    > .elementor-element-populated {
    padding: 0px 20px 0px 20px;
  }
  .elementor-1632
    .elementor-element.elementor-element-3b8451b
    .elementor-heading-title {
    font-size: 16px;
  }
  .elementor-1632 .elementor-element.elementor-element-61eafc9 {
    font-size: 14px;
    --container-widget-width: 100%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 100%);
    max-width: 100%;
  }
  .elementor-1632
    .elementor-element.elementor-element-2533881
    .elementor-custom-embed-play
    i {
    font-size: 50px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2533881
    .elementor-custom-embed-play
    svg {
    width: 50px;
    height: 50px;
  }
  .elementor-1632
    .elementor-element.elementor-element-7e980f3
    .elementor-heading-title {
    font-size: 40px;
  }
  .elementor-1632
    .elementor-element.elementor-element-ff44f87
    > .elementor-widget-container {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632 .elementor-element.elementor-element-ff44f87 {
    --container-widget-width: 85%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 85%);
    max-width: 85%;
  }
  .elementor-1632 .elementor-element.elementor-element-a706fab {
    font-size: 15px;
    --container-widget-width: 95%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 95%);
    max-width: 95%;
  }
  .elementor-1632 .elementor-element.elementor-element-c76a18b {
    font-size: 15px;
    --container-widget-width: 100%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 100%);
    max-width: 100%;
  }
  .elementor-1632 .elementor-element.elementor-element-18a2e49 {
    font-size: 15px;
  }
  .elementor-1632 .elementor-element.elementor-element-c6b7ab4 {
    width: 13%;
  }
  .elementor-1632 .elementor-element.elementor-element-6f91c17 {
    width: 85%;
  }
  .elementor-bc-flex-widget
    .elementor-1632
    .elementor-element.elementor-element-6f91c17.elementor-column
    .elementor-widget-wrap {
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-6f91c17.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-6f91c17
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-bea7822
    .elementor-heading-title {
    font-size: 18px;
  }
  .elementor-1632 .elementor-element.elementor-element-774d350 {
    width: 13%;
  }
  .elementor-1632 .elementor-element.elementor-element-ee5fecb {
    width: 85%;
  }
  .elementor-bc-flex-widget
    .elementor-1632
    .elementor-element.elementor-element-ee5fecb.elementor-column
    .elementor-widget-wrap {
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-ee5fecb.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-ee5fecb
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-8ef20f4
    .elementor-heading-title {
    font-size: 18px;
  }
  .elementor-1632 .elementor-element.elementor-element-928d7bb {
    width: 13%;
  }
  .elementor-1632 .elementor-element.elementor-element-49d1a3f {
    width: 85%;
  }
  .elementor-bc-flex-widget
    .elementor-1632
    .elementor-element.elementor-element-49d1a3f.elementor-column
    .elementor-widget-wrap {
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-49d1a3f.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-49d1a3f
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2f55f68
    .elementor-heading-title {
    font-size: 18px;
  }
  .elementor-1632 .elementor-element.elementor-element-4c28535 {
    width: 13%;
  }
  .elementor-1632 .elementor-element.elementor-element-5cf0ab5 {
    width: 85%;
  }
  .elementor-bc-flex-widget
    .elementor-1632
    .elementor-element.elementor-element-5cf0ab5.elementor-column
    .elementor-widget-wrap {
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-5cf0ab5.elementor-column.elementor-element[data-element_type="column"]
    > .elementor-widget-wrap.elementor-element-populated {
    align-content: center;
    align-items: center;
  }
  .elementor-1632
    .elementor-element.elementor-element-5cf0ab5
    > .elementor-element-populated {
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-f809d03
    .elementor-heading-title {
    font-size: 18px;
    line-height: 1.3em;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ae40b6
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ae40b6
    .elementor-image-box-title {
    font-size: 15px;
  }
  .elementor-1632
    .elementor-element.elementor-element-09b022a
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-09b022a
    .elementor-image-box-title {
    font-size: 15px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d04e7b0
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-d04e7b0
    .elementor-image-box-title {
    font-size: 15px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2964df9
    .elementor-image-box-img {
    margin-bottom: 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2964df9
    .elementor-image-box-title {
    font-size: 15px;
  }
  .elementor-1632 .elementor-element.elementor-element-242ef00 {
    font-size: 15px;
  }
  .elementor-1632 .elementor-element.elementor-element-0322905 {
    font-size: 15px;
  }
  .elementor-1632
    .elementor-element.elementor-element-0f6bebf
    .elementor-heading-title {
    font-size: 25px;
  }
  .elementor-1632
    .elementor-element.elementor-element-4c8472b
    > .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .elementor-1632
    .elementor-element.elementor-element-372a150
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-3eeaade {
    font-size: 15px;
  }
  .elementor-1632
    .elementor-element.elementor-element-3eeaade
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-66c0b3b
    .elementor-heading-title {
    font-size: 20px;
  }
  .elementor-1632 .elementor-element.elementor-element-66c0b3b {
    --container-widget-width: 30%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 30%);
    max-width: 30%;
  }
  .elementor-1632 .elementor-element.elementor-element-3aefd5a {
    font-size: 15px;
    --container-widget-width: 65%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 65%);
    max-width: 65%;
  }
  .elementor-1632
    .elementor-element.elementor-element-3aefd5a
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
  .elementor-1632
    .elementor-element.elementor-element-cf320d6
    > .elementor-element-populated {
    padding: 20px 20px 20px 20px;
  }
  .elementor-1632
    .elementor-element.elementor-element-a5cd4d0
    .elementor-heading-title {
    font-size: 30px;
  }
  .elementor-1632 .elementor-element.elementor-element-2ebfda8 {
    font-size: 15px;
  }
  .elementor-1632
    .elementor-element.elementor-element-2ebfda8
    > .elementor-widget-container {
    padding: 10px 10px 10px 10px;
  }
  .elementor-1632
    .elementor-element.elementor-element-a7f0a05
    .elementor-heading-title {
    font-size: 20px;
  }
  .elementor-1632 .elementor-element.elementor-element-a7f0a05 {
    --container-widget-width: 30%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 30%);
    max-width: 30%;
  }
  .elementor-1632 .elementor-element.elementor-element-b516e07 {
    font-size: 15px;
    --container-widget-width: 65%;
    --container-widget-flex-grow: 0;
    width: var(--container-widget-width, 65%);
    max-width: 65%;
  }
  .elementor-1632
    .elementor-element.elementor-element-b516e07
    > .elementor-widget-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }
}
@media (min-width: 768px) {
  .elementor-1632 .elementor-element.elementor-element-d6ceb4c {
    width: 50%;
  }
  .elementor-1632 .elementor-element.elementor-element-72817f8 {
    width: 50%;
  }
}
