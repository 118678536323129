/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Nexa';
    font-style: normal;
    font-weight: normal;
    src: local('Nexa Light'), url('Fontfabric - Nexa-Light.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Nexa Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Nexa Bold'), url('Fontfabric - Nexa-Bold.woff') format('woff');
    }