.elementor-1510 .elementor-element.elementor-element-b0d3bfd {
  margin-top: 50px;
  margin-bottom: 0px;
}
.elementor-1510
  .elementor-element.elementor-element-f38d219
  > .elementor-widget-wrap
  > .elementor-widget:not(.elementor-widget__width-auto):not(
    .elementor-widget__width-initial
  ):not(:last-child):not(.elementor-absolute) {
  margin-bottom: 40px;
}
.elementor-1510
  .elementor-element.elementor-element-f38d219
  > .elementor-element-populated {
  padding: 10px 30px 10px 30px;
}
.elementor-1510 .elementor-element.elementor-element-3496a44 {
  text-align: center;
}
.elementor-1510
  .elementor-element.elementor-element-3496a44
  .elementor-heading-title {
  color: #000000;
  font-family: "Poppins", Sans-serif;
  font-size: 30px;
  font-weight: 700;
}
.elementor-1510 .elementor-element.elementor-element-a8d2f33 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  fill: #ffffff;
  color: #ffffff;
  background-color: var(--e-global-color-460bc02);
}
.elementor-1510
  .elementor-element.elementor-element-a8d2f33
  .elementor-button:hover,
.elementor-1510
  .elementor-element.elementor-element-a8d2f33
  .elementor-button:focus {
  color: #ffffff;
  background-color: var(--e-global-color-76dacb9);
  border-color: var(--e-global-color-76dacb9);
}
.elementor-1510
  .elementor-element.elementor-element-a8d2f33
  .elementor-button:hover
  svg,
.elementor-1510
  .elementor-element.elementor-element-a8d2f33
  .elementor-button:focus
  svg {
  fill: #ffffff;
}
.elementor-1510
  .elementor-element.elementor-element-a8d2f33
  > .elementor-widget-container {
  margin: 5px 5px 5px 5px;
}
.elementor-1510 .elementor-element.elementor-element-a8d2f33 {
  width: var(--container-widget-width, 50%);
  max-width: 50%;
  --container-widget-width: 50%;
  --container-widget-flex-grow: 0;
}
.elementor-1510 .elementor-element.elementor-element-838aa36 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  fill: #ffffff;
  color: #ffffff;
  background-color: var(--e-global-color-460bc02);
}
.elementor-1510
  .elementor-element.elementor-element-838aa36
  .elementor-button:hover,
.elementor-1510
  .elementor-element.elementor-element-838aa36
  .elementor-button:focus {
  color: #ffffff;
  background-color: var(--e-global-color-76dacb9);
  border-color: var(--e-global-color-76dacb9);
}
.elementor-1510
  .elementor-element.elementor-element-838aa36
  .elementor-button:hover
  svg,
.elementor-1510
  .elementor-element.elementor-element-838aa36
  .elementor-button:focus
  svg {
  fill: #ffffff;
}
.elementor-1510
  .elementor-element.elementor-element-838aa36
  > .elementor-widget-container {
  margin: 5px 5px 5px 5px;
}
.elementor-1510 .elementor-element.elementor-element-838aa36 {
  width: var(--container-widget-width, 50%);
  max-width: 50%;
  --container-widget-width: 50%;
  --container-widget-flex-grow: 0;
}
.elementor-1510 .elementor-element.elementor-element-e55eb13 .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  fill: #ffffff;
  color: #ffffff;
  background-color: var(--e-global-color-460bc02);
}
.elementor-1510
  .elementor-element.elementor-element-e55eb13
  .elementor-button:hover,
.elementor-1510
  .elementor-element.elementor-element-e55eb13
  .elementor-button:focus {
  color: #ffffff;
  background-color: var(--e-global-color-76dacb9);
  border-color: var(--e-global-color-76dacb9);
}
.elementor-1510
  .elementor-element.elementor-element-e55eb13
  .elementor-button:hover
  svg,
.elementor-1510
  .elementor-element.elementor-element-e55eb13
  .elementor-button:focus
  svg {
  fill: #ffffff;
}
.elementor-1510
  .elementor-element.elementor-element-e55eb13
  > .elementor-widget-container {
  margin: 5px 5px 5px 5px;
}
.elementor-1510 .elementor-element.elementor-element-e55eb13 {
  width: var(--container-widget-width, 50%);
  max-width: 50%;
  --container-widget-width: 50%;
  --container-widget-flex-grow: 0;
}
.elementor-1510 .elementor-element.elementor-element-bf662aa .elementor-button {
  font-family: "Poppins", Sans-serif;
  font-size: 18px;
  font-weight: 600;
  fill: #ffffff;
  color: #ffffff;
  background-color: var(--e-global-color-460bc02);
}
.elementor-1510
  .elementor-element.elementor-element-bf662aa
  .elementor-button:hover,
.elementor-1510
  .elementor-element.elementor-element-bf662aa
  .elementor-button:focus {
  color: #ffffff;
  background-color: var(--e-global-color-76dacb9);
  border-color: var(--e-global-color-76dacb9);
}
.elementor-1510
  .elementor-element.elementor-element-bf662aa
  .elementor-button:hover
  svg,
.elementor-1510
  .elementor-element.elementor-element-bf662aa
  .elementor-button:focus
  svg {
  fill: #ffffff;
}
.elementor-1510
  .elementor-element.elementor-element-bf662aa
  > .elementor-widget-container {
  margin: 5px 5px 5px 5px;
}
.elementor-1510 .elementor-element.elementor-element-bf662aa {
  width: var(--container-widget-width, 50%);
  max-width: 50%;
  --container-widget-width: 50%;
  --container-widget-flex-grow: 0;
}
.elementor-1510
  .elementor-element.elementor-element-2489140
  > .elementor-widget-container {
  margin: 50px 0px 0px 0px;
}
#elementor-popup-modal-1510 .dialog-message {
  width: 380px;
  height: 100vh;
  align-items: flex-start;
}
#elementor-popup-modal-1510 {
  justify-content: flex-end;
  align-items: center;
  pointer-events: all;
  background-color: #00000075;
}
#elementor-popup-modal-1510 .dialog-close-button {
  display: flex;
  top: 1%;
  background-color: var(--e-global-color-76dacb9);
  font-size: 15px;
}
#elementor-popup-modal-1510 .dialog-widget-content {
  animation-duration: 1.2s;
  box-shadow: 2px 8px 23px 3px rgba(0, 0, 0, 0.2);
}
body:not(.rtl) #elementor-popup-modal-1510 .dialog-close-button {
  right: 84%;
}
body.rtl #elementor-popup-modal-1510 .dialog-close-button {
  left: 84%;
}
#elementor-popup-modal-1510 .dialog-close-button i {
  color: #ffffff;
}
#elementor-popup-modal-1510 .dialog-close-button svg {
  fill: #ffffff;
}
#elementor-popup-modal-1510 .dialog-close-button:hover i {
  color: #ffffff;
}
#elementor-popup-modal-1510 .dialog-close-button:hover {
  background-color: var(--e-global-color-460bc02);
}
@media (max-width: 1366px) {
  .elementor-1510
    .elementor-element.elementor-element-2489140
    > .elementor-widget-container {
    margin: 15px 0px 0px 0px;
  }
  #elementor-popup-modal-1510 .dialog-message {
    width: 400px;
  }
}
@media (min-width: 2400px) {
  .elementor-1510
    .elementor-element.elementor-element-a8d2f33
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
  .elementor-1510
    .elementor-element.elementor-element-838aa36
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
  .elementor-1510
    .elementor-element.elementor-element-e55eb13
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
  .elementor-1510
    .elementor-element.elementor-element-bf662aa
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
}
@media (max-width: 880px) {
  .elementor-1510 .elementor-element.elementor-element-2489140 img {
    width: 250px;
  }
}
@media (max-width: 767px) {
  .elementor-1510
    .elementor-element.elementor-element-f38d219
    > .elementor-widget-wrap
    > .elementor-widget:not(.elementor-widget__width-auto):not(
      .elementor-widget__width-initial
    ):not(:last-child):not(.elementor-absolute) {
    margin-bottom: 30px;
  }
  .elementor-1510
    .elementor-element.elementor-element-a8d2f33
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
  .elementor-1510
    .elementor-element.elementor-element-838aa36
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
  .elementor-1510
    .elementor-element.elementor-element-e55eb13
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
  .elementor-1510
    .elementor-element.elementor-element-bf662aa
    .elementor-button {
    padding: 15px 0px 15px 0px;
  }
} /* Start custom CSS */
#elementor-popup-modal-1510 .dialog-close-button {
  padding: 15px !important;
  border-radius: 100px !important;
} /* End custom CSS */
