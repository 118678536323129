/* #### Generated By: http://font.download #### */

    @font-face {
    font-family: 'Atami Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Atami Regular'), url('Atami-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Atami Display Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Atami Display Regular'), url('Atami-Display-Regular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Atami Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Atami Bold'), url('Atami-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Atami Stencil';
    font-style: normal;
    font-weight: normal;
    src: local('Atami Stencil'), url('Atami-Stencil.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Atami Stencil Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Atami Stencil Bold'), url('Atami-Stencil-Bold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Atami Display Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Atami Display Bold'), url('Atami-Display-Bold.woff') format('woff');
    }